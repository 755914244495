import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Email categories
    addEmailCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/email-categories', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/email-categories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEmailCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/email-categories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmailCategories(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/email-categories', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Email categories
    // Email address
    addEmailAddress(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/email-address', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEmailAddressBulk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/email-address-bulk', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailAddress(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/email-address/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEmailAddress(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/email-address/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmailAddress(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/email-address/${data.category_id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Blacklist
    addEmailBlacklist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/email-blacklist', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailBlacklist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/email-blacklist/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEmailBlacklist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/email-blacklist/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmailBlacklist(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/email-blacklist', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Email addressend
    // Email send
    sendEmail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/email-send', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmailSendDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/email-send-datas', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleEmailSendData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/email-send-datas/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Email loguna ait e posta adreslerinin gönderim kayıtları listesi
    fetchEmailSendDetailRecords(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/email-logs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
